import { isServer } from 'solid-js/web';
import { useAnalytics, useSeenEvents } from './context';
import type PostHog from 'posthog-js';
import type { AnalyticsEvent } from './events';

export function useTrackEvent(): Track {
	const ctx = useAnalytics();
	const seenEvents = useSeenEvents();
	if (!seenEvents) {
		return noop;
	}

	return (event: AnalyticsEvent, properties?: Record<string, unknown>, config?: Config) => {
		const eventId: `${AnalyticsEvent}-${string}` = `${event}-${JSON.stringify(properties)}`;
		if (config?.noDuplicates && seenEvents.has(eventId)) {
			return;
		}
		seenEvents.add(eventId);
		try {
			if (!isServer) {
				window.dataLayer?.push({
					event,
					...properties,
				});
			}
			ctx?.capture(event, properties);
			if (event === 'logOut' || event === 'unauthorizedLogOut') {
				// Reset the user to the analytics platform
				// https://posthog.com/docs/libraries/js
				ctx?.reset();
			}
		} catch {
			// ignore
		}
	};
}

const noop = () => undefined;

export function useCapture(): (typeof PostHog)['capture'] {
	const ctx = useAnalytics();
	if (!ctx) {
		return noop;
	}

	return ctx.capture.bind(ctx.capture);
}

type Config = {
	noDuplicates?: boolean;
};
export type Track = (event: AnalyticsEvent, properties?: Record<string, unknown>, config?: Config) => void;
